import _collection from "../internals/collection";
import _collectionStrong from "../internals/collection-strong";
var collection = _collection;
var collectionStrong = _collectionStrong;

// `Map` constructor
// https://tc39.es/ecma262/#sec-map-objects
collection("Map", function (init) {
  return function Map() {
    return init(this, arguments.length ? arguments[0] : undefined);
  };
}, collectionStrong);
export default {};